import React from "react";
import { Formik, Field, Form } from "formik";
import HashLoader from "react-spinners/HashLoader";
import Image from "../image";
import "./contact.css";

const ContactForm = ({ image, onFormSubmit, loading, onChangeSelect }) => {
  return (
    <section class="aboutColumns container" alignContent="center">
      <div class="aboutColumns-content ">
        <div class="aboutColumns-stack" id="form">
          <h2 class="aboutColumns-headline">{"Contact Us"}</h2>
          <p class="aboutColumns-subheading">{"Fill Out the Form"}</p>
          <p class="aboutColumns-cta"></p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              website: "",
              projectName: "",
              phone: "",
              project: "",
            }}
            id="aboutColumns_mc_embed_signup"
            name="mc-embedded-subscribe-form"
            className="newsletter -large validate"
            onSubmit={async (values, { resetForm }) => {
              onFormSubmit(values, resetForm);
              // resetForm({});
              // toast.success('Your request has been submitted');
            }}
          >
            <Form>
              <Field
                name="name"
                className="newsletter-input -large required email margin-btm-form xyz-form"
                type="text"
                placeholder="Your name"
              />
              <Field
                name="email"
                className="newsletter-input -large required email margin-btm-form xyz-form"
                type="email"
                placeholder="Email address"
              />
              <Field
                name="website"
                className="newsletter-input -large required email margin-btm-form xyz-form"
                type="text"
                placeholder="Current website"
              />
              <Field
                name="projectName"
                className="newsletter-input -large required email margin-btm-form xyz-form"
                type="text"
                placeholder="Project Name"
              />
              <Field
                name="phone"
                className="newsletter-input -large required email margin-btm-form xyz-form"
                type="text"
                placeholder="Phone Number"
              />
              <label
                for="styledSelect"
                className="custom-select margin-btm-form"
              >
                <select
                  id="styledSelect"
                  name="options"
                  onChange={(event) => onChangeSelect(event.target.value)}
                >
                  <option value="">TYPE OF PROJECT</option>
                  <option value="WEB BUILD">WEB BUILD</option>
                  <option value="SEO">SEO</option>
                  <option value="CONSULTATION">CONSULTATION</option>
                </select>
              </label>
              <button
                type="submit"
                class="submit"
                style={{ color: "white", width: "100%", padding: " 15px 0px" }}
              >
                {loading ? (
                  <div style={{ padding: " 15px 0px" }}>
                    <HashLoader size={30} color={"#fff"} />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          </Formik>
        </div>
      </div>
      <div class="aboutColumns-image">
        <Image
          src={image}
          imgClassName="aboutColumns-image"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default ContactForm;
