import React, { Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/header";
import Footer from "../components/footer";
import About from "../components/about";
import ContactForm from "../components/contactForm";

const Contact = ({ location }) => {
  const [loading, setLoading] = React.useState(false);
  const [project, setProject] = React.useState("");
  console.log("project", project);
  const onFormSubmit = async (submissionValues, resetForm) => {
    if (
      submissionValues.name &&
      submissionValues.email &&
      submissionValues.website &&
      submissionValues.projectName &&
      submissionValues.phone &&
      project
    ) {
      const today = new Date();
      setLoading(true);
      axios
        .post("https://leads.civsav.com/seocannabis/contact", {
          data: {
            name: submissionValues.name,
            email: submissionValues.email,
            website: submissionValues.website,
            projectName: submissionValues.projectName,
            phone: submissionValues.phone,
            project: project,
            date: today.toLocaleDateString("en-US"),
          },
        })
        .then((res) => {
          resetForm();
          setLoading(false);
          toast.success("Your request has been submitted");
        })
        .catch(() => {
          toast.error("Something went wrong!");
        });
    } else {
      toast.error("All Fields are Required!");
    }
  };

  return (
    <Fragment>
      <main>
        <Header pathname={location.pathname} />
        <About
          titlel1="Contact Us"
          desktopImg={"contactusimg"}
          mobileImg="contactusimg.png"
          page="contact"
        />
        <ContactForm
          image="home.png"
          onFormSubmit={onFormSubmit}
          loading={loading}
          onChangeSelect={setProject}
        />
        <Footer isTopborder={true} />
      </main>
      <ToastContainer />
    </Fragment>
  );
};

export default Contact;
